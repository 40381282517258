"use client";

import React, { useState } from "react";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faBan,
  faClock,
  faLock,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import globalVariables from "../GlobalVariable";

const FormAaa = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    service: "",
  });

  const [errors, setErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "This field is required";
    if (!formData.phone.trim()) newErrors.phone = "This field is required";
    if (!formData.service.trim() || formData.service === "Select Service")
      newErrors.service = "This field is required";
    return newErrors;
  };

  const sendSms = async (to, body) => {
    try {
      const response = await axios.post(`${globalVariables.domain}/sms`, {
        body: body,
        to: to,
      });
      setFormSuccess(true);
      setFormData({
        name: "",
        phone: "",
        service: "",
      });
      if (response.data.success) {
        console.log("siuuuu");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      console.log("Form Submitted", formData);
      // Aquí puedes agregar la lógica de envío POST.

      const timestamp = new Intl.DateTimeFormat("en-US", {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(new Date());
      const body = `🚨 New Lead from Website 🚨
        Name: ${formData.name}
        Phone: ${formData.phone}
        Service: ${formData.service}
        Timestamp: ${timestamp}`;

      const to = "+14086686725"; //+14085090579 Geo phone's  /// AAA furnace phone's +14086686725
      const to2 = "+14085090579";

      //   await sendSms(to, body);
      await sendSms(to2, body);

      // return;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-transparent">
      <div className="w-full max-w-xl p-6 rounded-lg">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                errors.name
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-500">{errors.name}</p>
            )}
          </div>
          <div>
            <input
              type="number"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                errors.phone
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
            )}
          </div>
          <div>
            <select
              name="service"
              value={formData.service}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 ${
                errors.service
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            >
              <option value="Select Service">Select Service</option>
              <option value="Heating Services">Heating Services</option>
              <option value="Cooling Services">Cooling Services</option>
              <option value="HVAC Maintenance">HVAC Maintenance</option>
              <option value="Residential Services">Residential Services</option>
              <option value="Commercial Services">Commercial Services</option>
              <option value="HVAC Repairs">HVAC Repairs</option>
              <option value="HVAC Installation">HVAC Installation</option>
            </select>
            {errors.service && (
              <p className="mt-1 text-sm text-red-500">{errors.service}</p>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-[#CA0712] rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              Request Service
            </button>
          </div>
          <div>
            {formSuccess && (
              <p style={{ color: "#fff" }}>
                ✅ Your submission was successful.
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAaa;
